
// :root {
    // --color-primary: #eb2f64;
    // --color-primary-light: #ff3366;
    // --color-primary-dark: #ba265d;
  
    // --color-grey-light-1: #faf9f9;
    // --color-grey-light-2: #f4f2f2;
    // --color-grey-light-3: #f0eeee;
    // --color-grey-light-4: #ccc;
  
    // --color-grey-dark-1: #333;
    // --color-grey-dark-2: #777;
    // --color-grey-dark-3: #999;
  
    // --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    // --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);
  
    // --line: 1px solid var(--color-grey-light-2);
  // }
  

// COLORS
$color-primary: #b8fb3c;
// $color-primary: #009999;
$color-primary-light: #11b2b2;
$color-primary-dark: #047575;

$color-text-primary: #576871;
$color-text-dark: #39424e;
$color-secondary-light: #ffb900;
$color-secondary-dark: #001437;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;


// responsiveness
$bp-largest: 75em;     // 1200px
$bp-large: 68.75em;    // 1100px
// $bp-medium: 59.3em;   // 900px flexible
$bp-small: 43.75em;     // 700px
$bp-smallest: 34.3em; // 550px
