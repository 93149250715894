
.label {
  display: block;
  color: #567191;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: capitalize;

  &.required {
    &::after {
      content: '*';
      color: #f00;
    }
  }
}
