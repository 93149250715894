.tile-content {
  text-align: left;
  border-left: 2px solid $color-primary;
  padding-left: 1rem;
  margin-bottom: 2rem;
  &__heading {
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: 1rem;
  }
  &__subheading {
    font-size: 1.4rem;
    color: $color-grey-dark;
    line-height: 1;
  }
  &__subheading2 {
    font-size: 1.2rem;
    color: $color-grey-dark;
    line-height: 1;
    margin-bottom: 2rem;
    font-weight: normal;
    margin-top: 0.5rem;
  }
  &__time-box {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 0.5rem;
    height: 1.8rem;
  }
  &__time {
    color: $color-primary;
    font-size: 1.2rem;
  }
}
