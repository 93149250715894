.loader {
    border: .5rem solid #f3f3f3; /* Light grey */
    border-top: .5rem solid #3498db; /* Blue */
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }