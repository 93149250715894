.science-card {
  width: 100%;
  margin: 5rem 1.5rem;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
  color: white;
  text-align: left;
  padding: 2rem 5rem;
  position: relative;
  @media only screen and (max-width: $bp-small) {
    margin: 3rem 1rem;
    width: auto;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 76px;
    background: #000;
    opacity: 0.2;
  }
  &--1 {
    background: linear-gradient(270deg, #268f8c 0%, #089995 100%);
    box-shadow: 0px 24px 48px 0px rgb(21 86 148 / 50%);
  }
  &--2 {
    background: linear-gradient(270deg, #001437b9 0%, #001437ba 100%);
    box-shadow: 0px 24px 48px 0px rgb(9 123 191 / 50%);
  }
  &--3 {
    background: linear-gradient(270deg, #009999 0%, #009999 100%);
    box-shadow: 0px 24px 48px 0px rgb(2 160 216 / 50%);
  }
  &__title {
    z-index: 1;
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
    color: white;
    line-height: 1;
  }
  &__subtitle {
    font-size: 1.8rem;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem 0 0 0;
    }
  }
  &__content {
    list-style: none;
    &-item {
      font-size: 1.4rem;
      line-height: 1.3;
      font-weight: 700;
      margin-top: 1rem;
      position: relative;
      padding-left: 2rem;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 8px;
        height: 17px;
        background: #4be6c0;
      }
    }
  }
}
.customer-card {
  width: 28rem;
  overflow: hidden;
  box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
  text-align: left;
  position: relative;
  @media only screen and (max-width: $bp-small) {
    width: auto;
    margin: 2rem 1.5rem;
  }
  &:hover {
    box-shadow: 0 16px 32px rgb(99 118 129 / 50%);
    cursor: pointer;
  }
  &__image {
    width: 100%;
    height: 20rem;
    object-fit: contain;
    padding: 0 1rem;
  }
  &__title {
    padding: 2rem 2rem 0 2rem;
    font-size: 2rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 28rem; /* some width */
    @media only screen and (max-width: $bp-small) {
      width: auto;
    }
  }
  &__link {
    padding: 1rem 2rem 1rem 2rem;
    font-size: 1.4rem;
    color: #00ace0;
  }
}


.aicts-card {
  width: 100%;
  margin: 7rem 1.5rem;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
  color: white;
  text-align: left;
  padding: 2rem 5rem;
  position: relative;
  @media only screen and (max-width: $bp-small) {
    margin: 3rem 1rem;
    width: auto;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 76px;
    background: #000;
    opacity: 0.2;
  }
  &--1 {
    background: linear-gradient(#82a344 0%, #82a344 0%);
    box-shadow: 0px 40px 48px 0px rgb(103 114 79 / 50%);
  }
  &--2 {
    background: linear-gradient(#82a344 0%, #82a344 0%);
    box-shadow: 0px 40px 48px 0px rgb(103 114 79 / 50%);
  }
  &--3 {
    background: linear-gradient(#82a344 0%, #82a344 0%);
    box-shadow: 0px 40px 48px 0px rgb(103 114 79 / 50%);
  }
  &__title {
    z-index: 1;
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
    color: white;
    line-height: 1;
  }
  &__subtitle {
    font-size: 1.8rem;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem 0 0 0;
    }
  }
  &__content {
    list-style: none;

    &-item {
      font-size: 1.4rem;
      line-height: 1.3;
      font-weight: 700;
      margin-top: 1rem;
      position: relative;
      padding-left: 2rem;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 10px solid #688236;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: 0;
        top: 5px;
        @media only screen and (max-width: $bp-large) {
          top: 2px;
        }
      }
    }
  }
}

// .card {
//   // FUNCTIONALITY
//   perspective: 150rem;
//   -moz-perspective: 150rem;
//   position: relative;
//   min-height: 50rem;

//   &__side {
//     //   min-height: 50rem;
//     height: 100%;
//     transition: all 0.8s ease;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     -webkit-backface-visibility: hidden;
//     backface-visibility: hidden;
//     border-radius: 3px;
//     overflow: hidden;
//     box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);

//     &--front {
//       background-color: $color-white;
//     }

//     &--back {
//       transform: rotateY(180deg);

//       &-1 {
//         background-image: linear-gradient(
//           to right bottom,
//           $color-secondary-light,
//           $color-secondary-dark
//         );
//       }

//       &-2 {
//         background-image: linear-gradient(
//           to right bottom,
//           $color-primary-light,
//           $color-primary-dark
//         );
//       }

//       &-3 {
//         background-image: linear-gradient(
//           to right bottom,
//           $color-tertiary-light,
//           $color-tertiary-dark
//         );
//       }
//     }
//   }

//   &:hover &__side--front {
//     transform: rotateY(-180deg);
//   }

//   &:hover &__side--back {
//     transform: rotateY(0);
//   }

//   // FRONT SIDE STYLING
//   &__picture {
//     background-size: cover;
//     height: 23rem;
//     background-blend-mode: screen;
//     -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
//     clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
//     border-top-left-radius: 3px;
//     border-top-right-radius: 3px;

//     &--1 {
//       background-image:
//             // linear-gradient(to right bottom, #ff5a5a, #ff5a5a),
//         url("../../../public/images/1.png");
//     }

//     &--2 {
//       background-image:
//             // linear-gradient(to right bottom, $color-primary-light, $color-primary-dark),
//         url("../../../public/images/2.png");
//     }

//     &--3 {
//       background-image:
//             //  linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark),
//         url("../../../public/images/3.png");
//     }

//     &--4 {
//       background-image: url("../../../public/images/4.png");
//     }
//     &--5 {
//       background-image: url("../../../public/images/5.png");
//     }
//     &--6 {
//       background-image: url("../../../public/images/6.png");
//     }
//     &--7 {
//       background-image: url("../../../public/images/7.png");
//     }
//     &--8 {
//       background-image: url("../../../public/images/8.png");
//     }
//     &--9 {
//       background-image: url("../../../public/images/9.png");
//     }
//     &--10 {
//       background-image: url("../../../public/images/10.png");
//     }
//   }

//   &__details {
//     padding: 0 3rem;

//     ul {
//       list-style: none;
//       width: 80%;
//       margin: 0 auto;

//       li {
//         text-align: center;
//         font-size: 1.5rem;
//         padding: 1rem;

//         &:not(:last-child) {
//           border-bottom: 1px solid $color-grey-light-2;
//         }
//       }
//     }
//   }

//   // FRONT SIDE STYLING
//   &__cta {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 90%;
//     text-align: center;
//   }

//   &__price-box {
//     text-align: center;
//     color: $color-white;
//     margin-bottom: 8rem;
//   }

//   &__price-only {
//     font-size: 1.4rem;
//     text-transform: uppercase;
//   }

//   &__price-value {
//     font-size: 3rem;
//     font-weight: 100;
//   }

//   //@include respond(tab-port) {
//   @media only screen and (max-width: $bp-small), only screen and (hover: none) {
//     height: auto;
//     border-radius: 3px;
//     width: 100%;
//     background-color: $color-white;
//     box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);

//     &__side {
//       height: auto;
//       position: relative;
//       box-shadow: none;

//       &--back {
//         transform: rotateY(0);
//         clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
//       }
//     }

//     &:hover &__side--front {
//       transform: rotateY(0);
//     }

//     &__details {
//       padding: 1rem 3rem;
//     }

//     // FRONT SIDE STYLING
//     &__cta {
//       position: relative;
//       top: 0;
//       left: 0;
//       transform: translate(0);
//       width: 100%;
//       padding: 7rem 4rem 4rem 4rem;
//     }

//     &__price-box {
//       margin-bottom: 3rem;
//     }

//     &__price-value {
//       font-size: 4rem;
//       @media only screen and (max-width: $bp-small) {
//         font-size: 2.5rem;
//         }
//     }
//   }
//   @media only screen and (max-width: $bp-small) {
//    margin-bottom: 3rem;
//    }
// }

// height of the area where the title was placed for cards was changed from 66px to 76px to incorporate all the contents when viewed on a small screen. margin top was added where the title is placed and its value is kept to 1rem. margin-bottol was changed from 2.5 around to 5rem for the same reason to incoroporate the title in that area.
// for 1 background: linear-gradient(270deg, #268f8c 0%, #089995 100%);    box-shadow: 0px 24px 48px 0px rgb(21 86 148 / 50%);
//  for 3 background: linear-gradient(270deg, #009999 0%, #009999 100%);
// for 2  background: linear-gradient(270deg, #001437b9 0%, #001437ba 100%);
// box-shadow: 0px 24px 48px 0px rgb(9 123 191 / 50%);
