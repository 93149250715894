.button {
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.textButton {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 3px;
  padding: 4px 8px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
