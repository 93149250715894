.container {
  width: 100%;
  text-align: center;
  img {
      margin-top: -8rem;
    width: 100%;
  }
}
.text {
  margin: 0 1rem;
  color: "#212121";
  font-size: 1.5rem;
  line-height: 25px;
  margin-bottom: 1.2rem;
}
