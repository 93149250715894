// .headroom {
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
// }
// .headroom--unfixed {
//   padding: 1.5rem;
//   position: relative;
//   transform: translateY(0);
// }
// .headroom--scrolled {
//   transition: transform 200ms ease-in-out;
// }
// .headroom--unpinned {
//   position: fixed;
//   transform: translateY(-100%);
// }
// .headroom--pinned {
//   box-shadow: 0 3px 10px 0 rgb(115 143 147 / 30%);
//   background-color: #fff;
//   position: fixed;
//   transform: translateY(0%);
// }
.navbar--shadow {
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
}
.navbar {
  transition: all 0.5s;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background-color: #fff;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0.5rem;
    max-width: 120rem;
    margin: 0 auto;
  }

  &-box {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &-logo-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-logo {
    // width: 5rem;
    // height: 5rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
    @media only screen and (max-width: $bp-smallest) {
      margin-top: 1rem;
    }
  }
  &-link {
    display: flex;
    list-style: none;
    font-size: 1.5rem;

    @media only screen and (max-width: $bp-smallest) {
      display: none;
    }
    &-item {
      margin: 0.4rem 1rem 0 0;
      padding: 0 0.3rem;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.3s;
      color: $color-text-primary;
      &:hover {
        border-bottom: $color-primary 3px solid;
        padding-bottom: 3px;
      }
    }

    &-menu {
      margin-right: 1rem;
      &:hover {
        cursor: pointer;
        .navbar-sublink {
          display: block;
        }
      }
    }
  }
  &-sublink {
    display: none;
    position: absolute;
    width: 100px;
    list-style: none;
      background-color: #fff;
    border: $color-primary 2px solid;
    padding: 0.5rem;
    li{
      margin-bottom: .5rem;
    }
  }

  &-right {
    display: flex;
    list-style: none;
    font-size: 1.7rem;
    @media only screen and (max-width: $bp-smallest) {
      display: none;
    }
    &-item {
      text-decoration: none;
      color: $color-text-dark;
      margin: 0.4rem 1rem 0 0;
      padding: 0 0.3rem;
      cursor: pointer;
    }
  }
  .active-link {
    border-bottom: $color-primary 3px solid;
    padding-bottom: 3px;
  }
}
.navigation {
  position: relative;

  &__uncheck {
    display: none;
  }
  &__check {
    display: none;
  }

  &__button {
    background-color: $color-white;
    height: 4.5rem;
    width: 4.5rem;
    position: fixed;
    top: 1rem;
    right: 1.8rem;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 1rem 1rem rgba($color-black, 0.1);
    text-align: center;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: $bp-smallest) {
      display: block;
    }
  }

  &__background {
    border-radius: 50%;
    position: fixed;
    top: 1rem;
    right: 1.8rem;
    background-image: radial-gradient(
      $color-primary-light,
      $color-primary-dark
    );
    z-index: 1000;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    display: none;
    @media only screen and (max-width: $bp-smallest) {
      display: block;
      height: 4.5rem;
      width: 4.5rem;
    }
  }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;

    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 3rem;
      font-weight: 300;
      padding: 1rem 3rem;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        $color-white 50%
      );
      background-size: 220%;
      transition: all 0.4s;
      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover {
      background-position: 100%;
      color: $color-primary;
      transform: translateX(1rem);
    }

    // &-active{
    //   background-position: 100%;
    //   color: $color-primary;
    //   transform: translateX(1rem);

    // }
  }

  //FUNCTIONALITY
  &__check ~ &__background {
    transform: scale(80);
  }

  &__check ~ &__nav {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }

  //ICON
  &__icon {
    position: relative;
    margin-top: 2.2rem;

    &,
    &::before,
    &::after {
      width: 2rem;
      height: 2px;
      background-color: $color-grey-dark-3;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.9rem;
    }
    &::after {
      top: 0.8rem;
    }
  }

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__check + &__button &__icon {
    background-color: transparent;
  }

  &__check + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__check + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}
