body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-text-primary;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading-primary {
  backface-visibility: hidden;
  
  &--main {
    color: $color-grey-dark-3;
    font-size: 5rem;
    line-height: 1.2  ;
    font-weight: 600;
    
    @media only screen and (max-width: $bp-small) {
      font-size: 3.5rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      font-size: 2.2rem;
    }
  }
  &--primary{
    color: $color-primary;
  }
  
  &--sub {
    color: $color-grey-dark-3;
    font-size: 3rem;
    font-weight: 400;
    @media only screen and (max-width: $bp-small) {
      font-size: 2.5rem;
    }
  }
}

.heading-secondary {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @media only screen and (max-width: $bp-small) {
    font-size: 1.8rem;
  }

  &:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }

  &--color {
    background-image: linear-gradient(
      to right,
      $color-primary-light,
      $color-primary-dark
    );
    -webkit-background-clip: text;
    color: transparent;
  }
}

.heading-tertiary {
  font-size: 1.8rem;
  line-height: 1.3;

  @media only screen and (max-width: $bp-small) {
    font-size: 1.4rem;
  }

  &--main {
    font-weight: 500;
    color: $color-grey-dark-2;
  }
  &--sub {
    font-size: 2rem;
    color: $color-secondary-dark;
    font-weight: 700;
  }
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
