@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;300;400;700;900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%;
  scroll-behavior: smooth;

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  position: relative;
}

::file-selector-button {
  display: none;
}
