.footer {
  border-top: 1px solid $color-grey-dark-2;
  &-logo {
    width: 10rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  &-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column;
    }
  }

  &-box {
    text-align: center;
    max-width: 120rem;
    margin: 5rem auto;
  }
  &__icons {
    background-color: #fff;
    border-radius: 5rem;
    padding: 1rem 3rem;

    &-link {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    &-icon {
      height: 4rem;
      padding: 5px;
      border-radius: 50%;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
  }
}

// .footer-box {
//   &__icons {
//     background-color: #fff;
//     border-radius: 5rem;
//     padding: 1rem 3rem;

//     &-link {
//       &:not(:last-child) {
//         margin-right: 1rem;
//       }
//     }
//     &-icon {
//       height: 4rem;
//       padding: 5px;
//       border-radius: 50%;
//       box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
//     }
//   }
// }
