.primary-button {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.2s;
    position: relative;
    font-size: 1.3rem;
    background-color: $color-primary;
    color: rgba($color-black, 0.7);

    //Change for the <button> element
    border: none;
    font-weight: bold;
    cursor: pointer;
  }
  // @media only screen and (max-width: $bp-smallest) {
  //   margin-bottom: 1rem;
  //   padding: 1rem 2rem;
  // }

  &:hover {
    // box-shadow: 0 05rem 0.5rem rgba($color-black, 0.2);
    background-color: $color-secondary-dark;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
.primary-button-main {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1rem 2.5rem;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.2s;
    position: relative;
    font-size: 1.8rem;
    background-color: $color-primary;
    color: rgba($color-black, 0.7);
    border: none;
    font-weight: bold;
    cursor: pointer;
  }

  &:hover {
    box-shadow: 0 1rem 1rem rgba($color-black, 0.2);
    background-color: $color-secondary-dark;
    transform: translateY(-3px);
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
.primary-button-shadow {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1rem 2.5rem;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.2s;
    position: relative;
    font-size: 1.8rem;
    background-color: $color-primary;
    color: rgba($color-black, 0.7);
    border: none;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 12px rgb(27 169 76 / 50%);
  }

  &:hover {
    background-color: $color-secondary-dark;
    box-shadow: none;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
.primary-button-shadow-small {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1rem 1.5rem;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.2s;
    position: relative;
    font-size: 1.4rem;
    background-color: $color-primary;
    color: rgba($color-black, 0.7);
    border: none;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 6px rgb(27 169 76 / 50%);
    margin-top: 3rem;
    margin-right: 3rem;
  }

  &:hover {
    background-color: $color-secondary-dark;
    box-shadow: none;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.btn-secondary {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1rem 2rem;
    display: inline-block;
    transition: all 0.2s;
    position: relative;
    font-size: 1.8rem;
    font-weight: bold;
    background-color: transparent;
    color: $color-secondary-dark;

    //Change for the <button> element
    border: 2px solid $color-secondary-dark;
    cursor: pointer;
    @media only screen and (max-width: $bp-smallest) {
      padding: 1rem 2rem;
    }
  }

  &:hover {
    border: 2px solid $color-secondary-dark;
    color: $color-secondary-dark;
    transform: translateY(-3px);
    box-shadow: 0 0.5rem 0.5rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }
}

.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 2rem;
    display: inline-block;
    border-radius: 5px;
    margin-top: 5px;
    transition: all 0.2s;
    position: relative;
    font-size: $default-font-size/3 * 2;
    color: rgba($color-black, 0.7);

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.2rem 0.5rem rgba($color-black, 0.2);
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
      background-color: $color-white;
    }
  }

  &--green {
    background-color: $color-primary;

    &::after {
      background-color: $color-primary;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}
.form-btn {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1rem 6rem;
    display: inline-block;
    transition: all 0.2s;
    position: relative;

    background-color: $color-primary;
    // color: $color-white;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:hover:enabled {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active:enabled,
  &:focus:enabled {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.2rem 0.5rem rgba($color-black, 0.2);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
}
