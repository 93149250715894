.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium { margin-bottom: 4rem !important; }
.u-margin-bottom-big { margin-bottom: 8rem !important; }

.u-margin-top-xssmall { margin-top: 1rem !important; }
.u-margin-top-small { margin-top: 4rem !important; }
.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }

.u-margin-right-small { margin-right: 1.5rem !important; }

.u-padding-bottom-big { padding-bottom: 8rem !important; }