.container {
    max-width: 120rem;
    margin: 8rem auto;
    // background-color: #fff; 
}
.heading{
    text-align: center;
    font-size: 3rem;
    margin: 3rem auto;

}