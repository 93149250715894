// .particle-component {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   z-index: -1;
// }
.scene-container > canvas {
  @media only screen and (max-width: $bp-smallest) {
    margin: 0 auto;
    width: 400px !important;
    height: 400px !important;
  }
}
.scene-container {
  @media only screen and (max-width: $bp-smallest) {
    width: 400px !important;
    height: 400px !important;
  }
}
.section-home {
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  @media only screen and (max-width: $bp-large) {
    flex-direction: column;
    text-align: center;
    margin: 5rem;
    height: auto;
  }
  @media only screen and (max-width: $bp-smallest) {
    margin: 2rem;
  }

  &__detail {
    flex: 0 0 50%;
    padding-left: 2rem;
    @media only screen and (max-width: $bp-large) {
      flex: auto;
      padding: 0;
    }
  }
  &__globe {
    flex: 0 0 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: $bp-large) {
      flex: auto;
    }
  }
}
.home-data {
  background-color: #f2f4f6;
  padding: 3rem;
  &__container {
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
    }
  }
  &__panel {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    text-align: center;
    gap: 1rem;
    &-heading {
      font-size: 2rem;
      color: $color-text-dark;
    }
    &-subheading {
      font-size: 3rem;
    }
    &-large {
      width: 70%;
      object-fit: contain;
    }

    img {
      // width: 10rem;
      height: 8rem;
      // filter: gray; /* IE5+ */
      // -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
      // -webkit-transition: all 0.4s ease-in-out;
    }
    // img:hover {
    // filter: none;
    // -webkit-filter: grayscale(0);
    // -webkit-transform: scale(1.01);
    // }
  }
}
.home-assement {
  max-width: 120rem;
  margin: 5rem auto;

  &-heading {
    font-size: 3rem;
    margin: 0 0 0 0;
    @media only screen and (max-width: $bp-large) {
      margin: 0 0 0 2rem;
    }
  }

  &-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    justify-items: center;
    @media only screen and (max-width: $bp-small) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
    transition: all 0.1s;
    &:hover {
      box-shadow: 0 3px 10px 0 rgb(99 118 129 / 30%);
      cursor: pointer;
    }
    &__image {
      width: 80%;
      padding: 4rem;
    }
    &__heading {
      font-size: 2rem;
      font-weight: normal;
      text-align: center;
    }
  }
}

// ////////////////////////////////////////
// // Science
.section-science {
  max-width: 120rem;
  margin: 0 auto;
  text-align: center;

  &__heading {
    margin-top: 5rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.9rem;
    font-weight: normal;
    @media only screen and (max-width: $bp-small) {
      margin: 2rem;
    }
  }
}
.science-cards {
  display: flex;
  margin-bottom: 4rem;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
}
.dark-section {
  background-color: #f3f7f7;
  padding: 7rem 1rem;

  &-content {
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
    }
  }
  &-1 {
    flex: 1;
    &-heading {
      font-size: 3.5rem;
      line-height: 1.4;
      font-weight: normal;
      color: black;
      padding: 1.5rem;
    }
    &-subheading {
      font-size: 1.7rem;
      line-height: 1.4;
      font-weight: normal;
      padding: 1rem 10rem 1rem 1.5rem;
    }
    &-list {
      list-style: none;
      padding: 0 1.5rem 0 1.5rem;
    }
    &-item {
      font-size: 1.2rem;
      position: relative;
      padding-left: 2rem;
      &::after {
        content: "";
        background-color: #1f3d83;
        position: absolute;
        top: 0.8rem;
        left: 0;
        height: 0.8rem;
        width: 1.2rem;
      }
    }
    &-button {
      padding: 1.5rem;
      margin-top: 1rem;
    }
  }
  &-2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    &-image {
      box-shadow: 0 14px 28px 0 rgb(115 143 147 / 50%);
      width: 100%;
      height: auto;
    }
  }
}
.light-section {
  padding: 7rem 1rem;

  &-content {
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media only screen and (max-width: $bp-small) {
      flex-direction: column-reverse;
    }
  }
  &-1 {
    flex: 1;
    padding-left: 4rem;
    &-heading {
      font-size: 3.5rem;
      line-height: 1.4;
      font-weight: normal;
      color: black;
      padding: 1.5rem;
    }
    &-subheading {
      font-size: 1.7rem;
      line-height: 1.4;
      font-weight: normal;
      padding: 1rem 10rem 1rem 1.5rem;
    }
    &-list {
      list-style: none;
      padding: 0 1.5rem 0 1.5rem;
    }
    &-item {
      font-size: 1.2rem;
      position: relative;
      padding-left: 2rem;
      &::after {
        content: "";
        background-color: #1f3d83;
        position: absolute;
        top: 0.8rem;
        left: 0;
        height: 0.8rem;
        width: 1.2rem;
      }
    }
    &-button {
      padding: 1.5rem;
      margin-top: 1rem;
    }
  }
  &-2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    &-image {
      box-shadow: 0 14px 28px 0 rgb(115 143 147 / 50%);
      width: 100%;
      height: auto;
    }
  }
}

// ////////////////////////////////////////
// // Hiring

.hiring-section {
  margin: 7rem 0;
  text-align: center;

  &-heading {
    font-size: 3rem;
    font-weight: 500;
    color: $color-text-dark;
  }
  &-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
}

// ////////////////////////////////////////
// // Customers
.section-customer {
  max-width: 120rem;
  margin: 0 auto;
  text-align: center;
  &__heading {
    margin-top: 5rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.9rem;
    font-weight: normal;
    @media only screen and (max-width: $bp-small) {
      margin: 2rem;
    }
  }
}
.customer-cards {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-top: 4rem;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
    margin-top: 0;
  }
}

// ////////////////////////////////////////
// // Resources
.section-resource {
  max-width: 120rem;
  margin: 0 auto;
  text-align: center;
  &__heading {
    margin-top: 5rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.9rem;
    font-weight: normal;
    @media only screen and (max-width: $bp-small) {
      margin: 2rem;
    }
  }
}

.company__0 {
  // height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
  &-imagebox {
    flex: 1;
    width: 80%;
    height: 80%;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &-content {
    flex: 1;
    text-align: left;
    padding-left: 3rem;
    // align-self: flex-start;
    @media only screen and (max-width: $bp-small) {
      padding: 3rem;
    }
    &-heading {
      // padding: 0 4rem 2rem 4rem;
      margin-bottom: 1rem;
      font-size: 3rem;
      line-height: 1.2;
    }
    &-heading2 {
      // padding: 0 4rem 2rem 4rem;
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 2rem;
    }
    &-subheading {
      // padding: 0 4rem 2rem 4rem;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
    &__icons {
      background-color: #fff;
      border-radius: 5rem;
      // padding: 1rem 3rem;

      &-link {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      &-icon {
        height: 4rem;
        padding: 5px;
        border-radius: 50%;
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.company__1 {
  // height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8rem 0;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
  &-imagebox {
    flex: 1;
    width: 80%;
    height: 80%;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &-content {
    flex: 1;
    text-align: left;
    padding-right: 3rem;
    @media only screen and (max-width: $bp-small) {
      padding: 3rem;
    }
    &-heading {
      // padding: 0 4rem 2rem 4rem;
      margin-bottom: 2rem;
      font-size: 4rem;
      line-height: 1.2;
    }
    &-heading2 {
      // padding: 0 4rem 2rem 4rem;
      font-size: 2.5rem;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
    &-subheading {
      // padding: 0 4rem 2rem 4rem;
      font-size: 1.5rem;
    }
  }
}
.company__2 {
  // height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8rem 0;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
  &-imagebox {
    flex: 1;
    width: 80%;
    height: 80%;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &-content {
    flex: 1;
    text-align: left;
    padding-left: 3rem;
    @media only screen and (max-width: $bp-small) {
      padding: 3rem;
    }
    &-heading {
      // padding: 0 4rem 2rem 4rem;
      margin-bottom: 2rem;
      font-size: 3rem;
      line-height: 1.2;
    }
    &-heading2 {
      // padding: 0 4rem 2rem 4rem;
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
    &-subheading {
      // padding: 0 4rem 2rem 4rem;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }
}
.resource__1 {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
    height: auto;
  }
  &-imagebox {
    flex: 1;
    width: 80%;
    height: 80%;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &-content {
    flex: 1;
    text-align: left;

    &-heading {
      padding: 0 4rem 2rem 4rem;
      font-size: 3rem;
      line-height: 1.2;
    }
    &-subheading {
      padding: 0 4rem 2rem 4rem;
      font-size: 1.5rem;
    }
  }
}
.resource__2 {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column-reverse;
    height: auto;
  }
  &-imagebox {
    flex: 1;
    width: 100%;
    height: 80%;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &-content {
    flex: 1;
    text-align: left;

    &-heading {
      padding: 0 8rem 2rem 0;
      font-size: 3rem;
      line-height: 1.2;
      @media only screen and (max-width: $bp-small) {
        padding: 0 4rem 2rem 4rem;
      }
    }
    &-subheading {
      padding: 0 8rem 2rem 0;
      font-size: 1.5rem;
      @media only screen and (max-width: $bp-small) {
        padding: 0 4rem 2rem 4rem;
      }
    }
  }
}
// ////////////////////////////////////////
// // Company
.section-company {
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  text-align: center;
  &__heading {
    // margin-top: 3rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__subheading {
    margin-top: 1rem;
    font-size: 1.9rem;
    font-weight: normal;
  }
  &-detail {
    padding: 0 2rem;
    text-align: left;
    &__heading {
      font-size: 2rem;
      font-weight: normal;
      color: black;
    }
    &__subheading {
      font-weight: normal;
      font-size: 1.5rem;
      padding-left: 1rem;
    }
  }
  &-form-link {
    text-decoration: none;
    color: $color-primary-dark;
  }
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

// ////////////////////////////////////////
// // Product page
.product-box {
  display: flex;
  max-width: 120rem;
  margin: 0 auto;
  align-items: center;

  @media only screen and (max-width: $bp-large) {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  &__heading {
    font-size: 2rem;

    @media only screen and (max-width: $bp-large) {
      margin: 0 3rem;
    }
  }
}
.funnel-wrap {
  // max-width: 1000px;
  max-width: 120rem;
  margin: 0 auto;
  // position: sticky;
  // position: -webkit-sticky;
  // top: 0;
  // left: 0;

  &-list {
    // margin: 5rem auto;
    position: relative;
    text-align: center;
    width: 50rem;
    list-style: none;

    @media only screen and (max-width: $bp-smallest) {
      width: 40rem;
    }

    &::before,
    &::after {
      border-color: white;
      border-style: solid;
      border-top-color: transparent;
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 0;
    }

    &::before {
      border-width: 0 11rem 45rem 0;
      border-right-color: transparent;
      left: 0;
      z-index: 9999;
    }
    &::after {
      border-width: 0 0 45rem 11rem;
      border-left-color: transparent;
      right: 0;
    }

    &-item {
      font-size: 2rem;
      @media only screen and (max-width: $bp-smallest) {
        font-size: 1.6rem;
      }
      &:hover {
        cursor: pointer;
      }
    }

    & &-item {
      padding: 2rem 0;
      margin-bottom: 15px;
      transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      position: relative;
    }
    & &-item:nth-child(1) {
      background-color: #ffffff;
      background-image: linear-gradient(
        -90deg,
        #2fb09c 0%,
        #d4f9f4 50%,
        #2fb09c 100%
      );
    }

    & &-item:nth-child(2) {
      background-color: #ffffff;
      background-image: linear-gradient(
        -90deg,
        #46c1c6 0%,
        #c9fcfd 50%,
        #46c1c6 100%
      );
      &::before {
        width: 95%;
      }
    }
    & &-item:nth-child(3) {
      background-color: #ffffff;
      background-image: linear-gradient(
        -90deg,
        #f6c84e 0%,
        #fef2d1 50%,
        #f6c84e 100%
      );
      &::before {
        width: 85%;
      }
    }
    & &-item:nth-child(4) {
      background-color: #ffffff;
      background-image: linear-gradient(
        -90deg,
        #e1793a 0%,
        #ffe5d4 50%,
        #e1793a 100%
      );
      &::before {
        width: 77%;
      }
    }
    & &-item:nth-child(5) {
      background-color: #ffffff;
      background-image: linear-gradient(
        -90deg,
        #e25f4b 0%,
        #f3d4ce 50%,
        #e25f4b 100%
      );
      &::before {
        width: 70%;
      }
    }
    & &-item:nth-child(n + 3) {
      // color: #fff;
      border-radius: 50px;
    }
    & &-item:last-child {
      border-bottom-right-radius: 5px;
    }
    & &-item::before {
      margin: 0 auto;
      height: 10px;
      border-radius: 50%;
      background: #d5d5d5;
      border: 0 solid rgba(255, 255, 255, 0.38);
      box-shadow: inset 0px 6px 10px 0px rgba(0, 0, 0, 0.33);
      content: "";
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      margin: auto;
      right: 0;
    }
  }
}

.skill-section {
  padding-top: 3rem;
  max-width: 120rem;
  margin: 0 auto;
  &__video-container {
    height: 400px;
    width: 600px;
    margin: 0 auto;
    @media only screen and (max-width: $bp-small) {
      width: 100%;
    }
  }
  &__video {
    height: 100%;
    width: 100%;
  }
  &__heading {
    margin: 5rem 0;
    font-size: 4rem;
    font-weight: normal;
    color: $color-secondary-dark;
    text-align: center;
    span {
      position: relative;
      &::before {
        content: "";
        width: 2.5rem;
        height: 3rem;
        background: $color-primary;
        position: absolute;
        right: -3.5rem;
        top: 8px;
        @media only screen and (max-width: $bp-small) {
          display: none;
        }
      }
    }
  }

  &-box {
    // height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
      gap: 3rem;
      align-items: flex-start;
    }
    &-imagebox {
      flex: 50%;
      text-align: center;
      // width: 80%;
      // height: 60%;
    }
    &-image {
      width: 80%;
      object-fit: contain;
      @media only screen and (max-width: $bp-small) {
        width: 60%;
      }
    }
    &-list {
      flex: 50%;
      // font-size: 1;
      // line-height: 1.714em;
      color: #576871;
      list-style: none;

      @media only screen and (max-width: $bp-large) {
        padding: 0 2rem;
      }
      // &-item:nth-child(1) {
      //   font-size: 2.2rem;
      //   padding-left: 0;
      //   &::before {
      //     display: none;
      //   }
      // }

      &-item {
        padding-left: 2rem;
        position: relative;
        font-size: 1.8rem;
        margin-top: 1rem;
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-left: 10px solid #1f3d83;
          border-bottom: 10px solid transparent;
          position: absolute;
          left: 0;
          top: 5px;
          @media only screen and (max-width: $bp-large) {
            top: 2px;
          }
        }
      }
    }
    &-sublist {
      color: #576871;
      list-style: none;

      &-item {
        padding-left: 2rem;
        position: relative;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          background: $color-primary;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
  }
}
//////////////////////////////////////////
// contact us
.contact-form {
  display: flex;
  // gap: 3rem;
  margin-bottom: 10rem;
  @media only screen and (max-width: $bp-large) {
    flex-direction: column;
  }
  &-content {
    flex: 0 0 50%;
    text-align: left;
  }
  &-mapbox {
    padding: 0 2rem;
    flex: 0 0 50%;
    width: 100%;
    @media only screen and (max-width: $bp-large) {
      height: 50rem;
      padding: 2rem;
    }
    &-map {
      width: 100%;
      height: 100%;
      @media only screen and (max-width: $bp-large) {
        height: 50rem;
      }
    }
  }
}
///////////////////////////////////////
// back to top
.back-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  font-size: 3rem;
  // font-weight: bold;
  width: 5rem;
  height: 5rem;
  background: $color-primary;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: 0 5px 10px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-to-top:hover {
  background: $color-secondary-dark;
}

// ////////////////////////////////////////
// // faq
.section-faq {
  max-width: 120rem;
  margin: 0 auto;
  &__heading {
    text-align: center;
    margin-top: 5rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.9rem;
    font-weight: normal;
  }
}
.faq-data {
  padding: 0 3rem;
  margin-bottom: 3rem;
}
// ////////////////////////////////////////
// // section-adobe-form
.section-adobe-form {
  background-image: url("../../assets/images/adobe-bg.png");
  background-repeat: no-repeat;
  height: 90vh;
  // height: 100%;
  background-attachment: fixed;
  // background-position: center;
  background-size: cover;
  padding: 5rem 0;

  @media only screen and (max-width: $bp-small) {
    padding: 0;
  }

  &-main {
    max-width: $bp-largest;
    margin: 0 auto;
    background-color: #fff;

    &-head {
      background: #753b0f;
      color: #fff;
      padding: 3rem;
    }
    &--heading {
      font-size: 3rem;
    }
    &--subheading {
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 2.5;
    }
    &-body {
      padding: 4rem;
      @media only screen and (max-width: $bp-small) {
        padding: 2rem;
      }
      &--subheading {
        span {
          color: #ae1515;
        }
        font-size: 1.2rem;
      }
    }
  }
  &-foot {
    background: #753b0f;
    color: #fff;
    padding: 3rem;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
    &--text {
      font-size: 1.2rem;
    }
  }
  &-link {
    text-decoration: none;
    color: white;
  }
}

// ////////////////////////////////////////
// // section-adobe-form
.section-kyron-form {
  background-image: url("../../assets/images/kyron-bg.jpeg");
  background-repeat: no-repeat;
  height: 90vh;
  // height: 100%;
  background-attachment: fixed;
  // background-position: center;
  background-size: cover;
  padding: 5rem 0;

  @media only screen and (max-width: $bp-small) {
    padding: 0;
  }

  &-main {
    max-width: $bp-largest;
    margin: 0 auto;
    background-color: #fff;

    &-head {
      background: #753b0f;
      color: #fff;
      padding: 3rem;
    }
    &--heading {
      font-size: 3rem;
    }
    &--subheading {
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 2.5;
    }
    &-body {
      padding: 4rem;
      @media only screen and (max-width: $bp-small) {
        padding: 2rem;
      }
      &--subheading {
        span {
          color: #ae1515;
        }
        font-size: 1.2rem;
      }
    }
  }
  &-foot {
    background: #753b0f;
    color: #fff;
    padding: 3rem;
    @media only screen and (max-width: $bp-small) {
      padding: 2rem;
    }
    &--text {
      font-size: 1.2rem;
    }
  }
  &-link {
    text-decoration: none;
    color: white;
  }
}

// learn page
.section-learn {
  max-width: 120rem;
  margin: 0 auto;
  &-image {
    width: 100%;
  }
  &-button {
    margin: 2rem 0 5rem 0;
    text-align: center;

    &-heading {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1;
      color: $color-text-dark;
    }
    &-buttons {
      display: flex;
      gap: 1rem;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

// modal
.new-college-modal {
  width: 400px;
  &-button {
    // background: none;
    border: none;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    margin-top: 20px;
    width: 100%;
    color: #000;
    &.loading {
      cursor: progress !important;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

// aicts

.section-aicts {
  margin-top: 2rem;
  text-align: center;
  // max-width: 120rem;
  // margin: 0 auto;

  &-image {
    width: 80%;
    margin-top: 2.5rem;
    margin-bottom: 6rem;
  }

  &-heading {
    font-size: 4rem;
    font-weight: normal;
    color: $color-black;
    line-height: 1;
  }

  &-subHeading {
    margin: 5rem auto 4rem auto;
    font-size: 2rem;
    font-weight: bold;
    color: $color-black;
    text-align: left;
  }

  &-body {
    text-align: left;
    font-size: 1.5rem;

    &-list {
      color: #576871;
      list-style: none;

      @media only screen and (max-width: $bp-large) {
        padding: 0 2rem;
      }

      &-item {
        padding-left: 3rem;
        position: relative;
        font-size: 1.5rem;
        margin-top: 1.5rem;
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-left: 10px solid #1f3d83;
          border-bottom: 10px solid transparent;
          position: absolute;
          left: 0;
          top: 5px;
          @media only screen and (max-width: $bp-large) {
            top: 2px;
          }
        }
      }
    }
    &-sublist {
      color: #576871;
      list-style: none;

      &-item {
        padding-left: 2rem;
        position: relative;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          background: $color-primary;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }
    }
  }

  &-table {
    font-size: 1.5rem;

    &-structure,
    th,
    td {
      border: 1px solid;
      border-collapse: collapse;
    }

    &-heading {
      font-weight: normal;
      color: $color-black;

      &-sNo {
        width: 8%;
      }
      &-package {
        width: 42%;
      }
      &-commencingDates {
        width: 20%;
      }

      &-fee {
        width: 25%;
      }
    }
    &-rowData {
      padding: 1rem;
      max-width: 70%;

      &-fee {
        padding: 1.8rem;
        font-weight: bold;
      }

      // &-span {
      //   color: black;
      //   text-decoration: line-through !important;
      // }
    }
  }
}

.aicts-cards {
  display: flex;
  margin-bottom: 6rem;
  margin-top: 6rem;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
}

.section-aicts-footer {
  margin-top: 13rem;
}

//error
.section-error {
  text-align: center;
  margin-top: 0;
  &-image {
    width: 40%;
    height: 40%;
    margin-top: 1rem;
    margin-left: 30%;
    margin-right: 30%;
  }

  &-heading {
    font-weight: normal;
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 1;
  }

  &-button {
    background-color: transparent;
    margin-top: 2rem;
    padding: 0.6rem 2rem;
    border-radius: 5px;
    border: 1px solid #009696;

    &-text {
      color: #009696;
      font-size: 1.5rem;
      text-decoration: none;
    }
  }
}

// ////////////////////////////////////////
// // Privacy
.section-privacy {
  max-width: 120rem;
  margin: 0 auto;
  &__heading {
    text-align: center;
    margin-top: 2rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__heading2 {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: normal;
    color: black;
    margin: 2rem;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 2rem;
  }
  &__list {
    margin: 0 5rem;
    font-size: 1.5rem;
  }
  &__inside {
    margin: 0 3rem;
  }
}

// ////////////////////////////////////////
// // TOS
.section-tos {
  max-width: 120rem;
  margin: 0 auto;
  &__heading {
    text-align: center;
    margin-top: 2rem;
    font-size: 4rem;
    font-weight: normal;
    color: black;
  }
  &__heading2 {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: normal;
    color: black;
    margin: 2rem;
  }
  &__subheading {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 2rem;
  }
  &__list {
    margin: 0 5rem;
    font-size: 1.5rem;
  }
  &__inside {
    margin: 0 3rem;
  }
}
