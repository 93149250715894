.form {
  margin: 0 2rem;
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    // background-color: rbga($color-white, .5);
    box-shadow: 0 1rem 1rem rgba($color-black, 0.1);
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-secondary-dark;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__radio-group {
    width: 49%;
    display: inline-block;
  }

  &__radio-input {
    display: none;
  }

  &__radio-label {
    font-size: $default-font-size;
    cursor: pointer;
    position: relative;
    padding-left: 4.5rem;
  }

  &__radio-button {
    height: 3rem;
    width: 3rem;
    border: 5px solid $color-primary;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;

    &::after {
      content: "";
      display: block;
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-primary;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}

.question-box {
  padding: 2rem 0;
  &__label {
    font-size: 1.5rem;
    // font-weight: 700;
    display: block;
    color: black;
    span {
      color: #ae1515;
    }
  }
  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 2px;
    border: 1px solid black;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 1rem rgba($color-black, 0.1);
      border-bottom: 1px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 1px solid $color-secondary-dark;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }
  }

  &__error {
    color: red;
    font-size: 1.2rem;
  }

  &__searchInput {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    margin-top: 1rem;
    border-radius: 2px;
    border: 1px solid black;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 1rem rgba($color-black, 0.1);
      border-bottom: 1px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 1px solid $color-secondary-dark;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

    & .css-1s2u09g-control {
      border-color: transparent !important;
      box-shadow: 0 0 0 1px transparent;
    }
    & .css-1pahdxg-control {
      border-color: transparent !important;
      box-shadow: 0 0 0 1px transparent;
    }
    &-button{
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-weight: 600;
      color: $color-primary-dark
    }
  }
}
.input-box {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid black;
  padding: 1rem;
  margin-top: 1rem;

  span {
    font-size: 1.4rem;
    font-family: inherit;
    display: block;
    margin-right: 0.5rem;
  }
  &__input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    width: 100%;
    display: block;
  }
}
