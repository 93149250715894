.parallaxContainer {
  // height: 550px;
  position: relative;
  margin-top: -2rem;
  .parallax {
    height: 500px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .overlayBox {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    color: white;
    transform: translate(-50%, -50%);
    text-align: center;
    h2 {
      font-size: 3.5rem;
      line-height: 130%;
      padding-bottom: 40px;
      font-weight: 500;
    }
    .applyButton {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff;
      border: 3px solid #fff;
      border-radius: 30px;
      padding: 6px 39px;
      text-transform: uppercase;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 800px) {
  .ourCompany {
    flex-direction: column;
    &Text {
      padding: 0 10px;
      width: 100% !important;
    }
  }
}
.ourCompany {
  display: flex;
  gap: 2rem;
  align-items: center;

  &Text {
    width: 50%;
    text-align: center;
    h3 {
      font-size: 3rem;
      font-weight: 400;
      color: #000000;
      margin-bottom: 2rem;
    }
    p {
      color: "#44434";
      font-size: 1.6rem;
      line-height: 26px;
      margin-bottom: 2rem;
    }
  }

  &Video {
    margin: 0 auto;
    height: 30rem;
    width: 50rem;
  }
}

.jobOpening {
  h3 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }
  .sheCodePoster {
    text-align: center;
    img {
      width: 70%;
    }
  }
}
.jobOpening2 {
  padding: 10px;
  color: white;
  > div {
    h1 {
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 1.5rem;
      font-weight: 500;
    }
    .whatYouDo {
      margin-top: 3rem;
      h6 {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 8px;
      }
      p {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }

    ul {
      li {
        position: relative;
        list-style: none;
        font-size: 1.5rem;
        font-weight: 500;
        margin-left: 20px;
        margin-top: 10px;
        &::before {
          position: absolute;
          content: "";
          left: -17px;
          top: 4px;
          width: 15px;
          height: 15px;
          background: url("https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/o_bullets.png")
            no-repeat;
          background-size: contain;
        }
      }
    }
    .apply {
      display: inline-block;
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 400;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 30px;
      padding: 6px 39px;
      text-transform: uppercase;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}

.adobeForAll {
  padding: 0 10px;
  h3 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }
  p {
    color: "#212121";
    font-size: 1.5rem;
    line-height: 22px;
    margin-bottom: 1.2rem;
  }
  .whiteBox {
    background: #fff;
    padding: 2.5rem;
    margin: 3rem 0;
    border-bottom: 3px solid #00aaff;
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 10%);
    &__ul {
      list-style: none;
      &__li {
        font-size: 1.5rem;
        line-height: 1.3;
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 2rem;
        position: relative;
        padding-left: 2rem;
        line-height: 22px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 2px;
          width: 8px;
          height: 17px;
          background: #4be6c0;
        }
      }
    }
  }
  .readMoreButton {
    text-align: center;

    a {
      display: inline-block;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 600;
      color: #000;
      border: 2px solid #000;
      border-radius: 30px;
      padding: 6px 39px;
      text-transform: uppercase;
      margin-top: 20px;
    }
  }
}

.videosContainer {
  background: #f3f7f7;
  padding: 0.2rem 10px;
  h3 {
    font-size: 3rem;
    margin-bottom: 5rem;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }
  .videosBox {
    display: flex;
    gap: 20px;
  }
  @media screen and (max-width: 800px) {
    .videosBox {
      flex-direction: column;
    }
  }
}
.galleryContainer {
  h3 {
    font-size: 3rem;
    margin-bottom: 5rem;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }

  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
    img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
    }
  }

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
}

.blogContainer {
  text-align: center;
  h3 {
    font-size: 2rem;
    margin-bottom: 3rem;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }
  .readMoreButton {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
    color: #000;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 6px 39px;
    text-transform: uppercase;
    transition: all 0.5s;
    &:hover {
      color: #fff;
      background: #fe0000;
      border: 2px solid #fe0000;
    }
  }
}
.footer {
  background: #dbdbdb;
  padding: 1px 0;
  .footerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      p {
        font-size: 1.2rem;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .footerBox {
      flex-direction: column;
      text-align: center;
      gap: 20px;
    }
  }
}
