.modalOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 214748364;
  visibility: hidden;

  &.open {
    visibility: visible;
  }

  .modalBox {
    position: fixed;
    background-color: #fff;
    outline: none;
    box-shadow: 5px 10px 50px rgba(41, 43, 88, 0.2);
    border-radius: 20px;
    align-items: center;
    transition: 0.4s;
    padding: 20px;
    @keyframes animateIn {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes animateOut {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    &.open {
      animation: animateIn 0.4s;
    }

    &.close {
      animation: animateOut 0.4s;
    }

    .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      border: none;
      background: none;
      color: black;
      font-size: 1rem;
      z-index: 10;
      color: #567191;
      &.leftClose {
        right: unset;
        left: 15px;
      }
      width: 1.7rem;
      height: 1.7rem;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: #f5f6f7;
      }
    }

    .closeHoverless {
      &:hover {
        background: none;
      }
    }
  }
}
